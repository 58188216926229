/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';


const useOutsideClick = ( ref, callback, when ) => {
    const savedCallback = useRef( callback );

    useEffect(() => {
        savedCallback.current = callback;
    });

    const handler = (e) => {
        if( ref.current && !ref.current.contains(e.target)) {
            savedCallback.current();
        }
    };

    useEffect(() => {
        if (when) {
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
    }, [when]);
}

export const DropdownAll = ({ items, itemsSelected, handleItemsSelected, oneSelected = false, texto = "Selecciona..." }) => {
    const { colores } = useSelector(state => state.ui);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [visibility, setVisibility] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredItems, setFilteredItems] = useState(items);
    
    const dropdownMenuRef = useRef();

    useEffect(() => {
        setFilteredItems(items);
    }, [items]);

    const showDropdown = () => {        
        setVisibility(true);        
    }

    const hideDropdown = () => {
        setVisibility(false);
        setSearchTerm(''); // Limpiar búsqueda al cerrar
        setFilteredItems(items); // Restaurar items originales
    }

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);
        
        const filtered = items.filter(item => 
            (item.nombre || item.titulo || '').toLowerCase().includes(value)
        );
        setFilteredItems(filtered);
    }

	const handleOnChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            const foundItem = items.find(v => v._id === value);
            const itemWithNombre = {
                ...foundItem,
                nombre: foundItem.nombre || foundItem.titulo || ''
            };
            if(!oneSelected){
                handleItemsSelected([...itemsSelected, itemWithNombre]);
            }else{
                handleItemsSelected([itemWithNombre]);
                hideDropdown(); // Cerrar en selección única
            }
        } else {
            handleItemsSelected(itemsSelected.filter(v => v._id !== value));
        }
        hideDropdown(); // Cerrar después de cada selección/deselección
    }

    const handleContainerClick = (e, item) => {
        // Si el click fue directamente en el checkbox o label, no hacer nada
        if (e.target.type === 'checkbox' || e.target.tagName === 'LABEL') return;

        const isItemChecked = isChecked(item);
        if (!isItemChecked) {
            const itemWithNombre = {
                ...item,
                nombre: item.nombre || item.titulo || ''
            };
            if(!oneSelected){
                handleItemsSelected([...itemsSelected, itemWithNombre]);
            }else{
                handleItemsSelected([itemWithNombre]);
            }
        } else {
            handleItemsSelected(itemsSelected.filter(v => v._id !== item._id));
        }
        hideDropdown(); // Cerrar después de cada selección/deselección
    }


	const isChecked = (item) => {
		return itemsSelected?.some(v => v?._id === item?._id)
	}

    useOutsideClick( dropdownMenuRef, hideDropdown, visibility );
    

    return (
        <div className={`brb-select`}>
            <div
                className={`brb-select-container visible ${ (visibility) ? 'active' : '' }`}
                onClick={ () => (visibility) ? hideDropdown() : showDropdown() }
            >
                <p>{ (itemsSelected?.length > 0) ?  itemsSelected?.map(item => item?.nombre).join(', ') :  texto }</p>
                <div className="right-zone">                    
                    <span className="select-arrow select-arrow-down"></span>
                </div>
            </div>
            <div ref={dropdownMenuRef} className={`brb-select-options select-promocion ${ visibility ? 'active' : '' }`}>
                <div className="search-container">
                    <input
                        type="text"
                        placeholder="Buscar..."
                        value={searchTerm}
                        onChange={handleSearch}
                        onClick={(e) => e.stopPropagation()}
                        style={{
                            width: '100%',
                            padding: '8px',
                            marginBottom: '8px',
                            border: `1px solid ${colores.colorPrincipal}`,
                            borderRadius: '4px'
                        }}
                    />
                </div>
                {
                    filteredItems.map((item) => (
                        <div 
                            key={item._id} 
                            className="brb-select-option" 
                            style={{ backgroundColor: colores.color6 }}
                            onClick={(e) => handleContainerClick(e, item)}
                        >
                            <input 
                                type="checkbox" 
                                id={item._id} 
                                name={item._id} 
                                value={item._id} 
                                checked={isChecked(item)} 
                                onChange={handleOnChange} 
                            />
                            <label htmlFor={item._id}>{item.nombre || item.titulo}</label>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
