import React, { useEffect, useState, Suspense, lazy, useContext, useRef } from 'react';
import uuid from 'react-uuid';
import moment from 'moment-timezone';
//import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
//import Draggable, {DraggableCore} from 'react-draggable';

import { useDispatch, useSelector } from 'react-redux';
import { activeCita, startSaveCita } from '../../../actions/citas';
import { activeUsuario } from '../../../actions/usuarios';
import { activeServicio } from '../../../actions/servicios';
import { activeSucursal } from '../../../actions/sucursales';
import { activeProfesional } from '../../../actions/profesionales';
import { SocketContext } from '../../../context/SocketContext';
import { closeLoading, openLoading } from '../../../actions/ui';
import { YlmModalTwoButtons } from '../../../components/main/YlmModalTwoButtons';
const DailyCalendarItem = lazy(async () => (await import('./DailyCalendarItem')));

const timezone = process.env.REACT_APP_TIMEZONE;
const usarSockets = process.env.REACT_SOCKETS;

export const DailyCalendar = ({ dailyItem, viewValue, editCita, setEditCita, activeDnd, setActiveDnd }) => {

    const dispatch = useDispatch();
    const { uid } = useSelector(state => state.auth);
    const { empresas } = useSelector(state => state.empresas);
    const { socket } = useContext(SocketContext);
    const [espaciadoEmpresa, setEspaciadoEmpresa] = useState(5);
    const [franjas, setFranjas] = useState([]);
    const [altoFranja, setAltoFranja] = useState(0);
    const [altoFranjaCita, setAltoFranjaCita] = useState(0);
    const [altoFranjaDraggable, setAltoFranjaDraggable] = useState(espaciadoEmpresa * 2.535);
    const { variantes } = useSelector(state => state.variantes);
    const { servicios } = useSelector(state => state.servicios);

    const [dataModal, setDataModal] = useState({
        title: '¿Modificar cita?',
        text: ['¿Estás seguro de modificar esta cita?'],
        buttonText: 'Estoy seguro',
        button2Text: 'Cancelar'
    });
    const [modalTwoButtonsOpened, setModalTwoButtonsOpened] = useState(false);
    const [modalResponse, setModalResponse] = useState(100);
    const [modalWaiting, setModalWaiting] = useState({});

    const [over, setOver] = useState(false);
	const [scrollPosition, setScrollPosition] = useState(false);

    const vistaCalendario = useRef(null);

    //const altoFranja = espaciadoCitas * 2.5 ;
    //const altoFranjaDraggable = espaciadoCitas * 2.535;
    //const altoFranjaCita = espaciadoCitas * 2.535;

    const horario = ['8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21'];

    const [cuadrante, setCuadrante] = useState([]);

    /* useEffect(() => {
      console.log( cuadrante );
    }, [cuadrante]) */

    useEffect(() => {
        if (espaciadoEmpresa > 0) {
            setAltoFranja(espaciadoEmpresa * 2.5);
            setAltoFranjaCita(espaciadoEmpresa * 2.535);
        }
    }, [espaciadoEmpresa])

    useEffect(() => {
        let newFranjas = [];
        for (let i = 0; i < 60; i = i + espaciadoEmpresa) {
            let nombreFranja = i.toString();
            if (nombreFranja.length < 2) {
                nombreFranja = '0' + nombreFranja;
            }
            newFranjas = [...newFranjas, nombreFranja];
        }
        setFranjas(newFranjas);
    }, [espaciadoEmpresa]);


    useEffect(() => {
        if (dailyItem) {
            generaCuadrante();
			if(scrollPosition){
				vistaCalendario.current.scrollTop = scrollPosition;
			}
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dailyItem, franjas]);

    useEffect(() => {
        if(modalWaiting.reserva){
			if (modalResponse === 1) {				
				dispatch(startSaveCita(modalWaiting.reserva));				
				dispatch(startSaveCita(modalWaiting.reservaRelacionada));
			}
			setModalWaiting({});
			setModalResponse(100);
			dispatch(closeLoading());
		}else{
			if (modalWaiting._id) {
				if (modalResponse === 1) {
					dispatch(startSaveCita(modalWaiting));
				}
				setModalWaiting({});
				setModalResponse(100);
				dispatch(closeLoading());
			}
		}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalResponse]);

	const handleScroll = (event) => {
		setScrollPosition(event.target.scrollTop)
	 }

    const generaCuadrante = () => {
        //console.log('Entro en generaCuadrante');
        //console.log( 'dailyItem', dailyItem );
        let newColumns = [];
        let initialScrollPoint = -15;
        dailyItem.calendarItems.forEach((calendarItem, currentColumn) => {
            if (calendarItem.items[0]?.profSelected && calendarItem.items[0]?.sucursalSelected) {
                //console.log( 'Mismo profesional y misma sucursal' );
                let newFranjas = [];
                let currentTop = 0;
                horario.map((hora) => {
                    const horaDosCifras = (hora.length < 2) ? `0${hora}` : hora;
                    let huecoAvailable = 0;
                    //Franjas son los minutos de cada hora dependiendo del espaciado 00,10,20...
                    //console.log( 'franjas', franjas );
                    franjas.map((minutos, idxFranja) => {
                        const newItem = {
                            empty: true,
                            available: huecoAvailable <= 0,
                            idProfesional: null,
                            currentColumn,
                            idx: idxFranja,
                            currentTop
                        }
                        calendarItem.items.forEach((item, idx) => {
                            /* const franjaInicio = moment(`${moment( item.fecha ).format('YYYY-MM-DD')}T${horaDosCifras}:${minutos}:00`);
                            const franjaFinal = moment(`${moment( item.fecha ).format('YYYY-MM-DD')}T${horaDosCifras}:${minutos}:00`); */
                            const franjaInicio = moment.tz(`${moment.tz(item.fecha, timezone).format('YYYY-MM-DD')}T${horaDosCifras}:${minutos}:00`, timezone);
                            const franjaFinal = moment.tz(`${moment.tz(item.fecha, timezone).format('YYYY-MM-DD')}T${horaDosCifras}:${minutos}:00`, timezone);
                            franjaFinal.add(espaciadoEmpresa, 'minutes');
                            
                            
                            if (moment.tz(item.fecha, timezone).isSameOrAfter(franjaInicio) && moment.tz(item.fecha, timezone).isBefore(franjaFinal)) {
                                const profesionalDelay = parseInt(item.cita.profesionalDelay);
                                const usuarioDelay = parseInt(item.cita.usuarioDelay);
                                const duracionCita = parseInt(item.cita.duracion) + profesionalDelay + usuarioDelay;

                                newItem.hora = `${moment.tz(item.fecha, timezone).format('H:mm')}`;
                                newItem.horaFin = `${moment.tz(item.fecha, timezone).add(duracionCita, 'minutes').format('H:mm')}`;
                                newItem.empty = false;
                                newItem.duracion = duracionCita;
                                newItem.available = false;
                                newItem.idxProf = item.idxProf;
                                //if(item.profesional._id==="66426c65f05fb7a2833dc02e") debugger;
                                //console.log("profesional: ", item.profesional._id)
                                newItem.cita = item.cita;
                                huecoAvailable = duracionCita / espaciadoEmpresa - 1;
                            }
                            if (item) {
                                newItem.idProfesional = item.cita.profesional._id;
                            }
                            
                        });
                        if (newItem.empty && huecoAvailable > 0) {
                            huecoAvailable--;
                        }
                        newItem.time = `${horaDosCifras}:${minutos}`;
                        if (!newItem.empty) {
                            if (initialScrollPoint === -15 || initialScrollPoint > newItem.currentTop) {
                                initialScrollPoint = newItem.currentTop;
                            }

                            newFranjas = [
                                ...newFranjas,
                                newItem
                            ]
                        }
                        currentTop += espaciadoEmpresa * 2.535;
                        return minutos;
                    });
                    return hora;
                });
                newColumns = [
                    ...newColumns,
                    newFranjas
                ]
            }
        });
        // console.log( 'calculaDistancias', calculaDistancias( newColumns ));
        scrollToPrimeraCita(initialScrollPoint);
        setCuadrante(newColumns);
    };

    const scrollToPrimeraCita = (value) => {
        if (value > 0) {
            // console.log( 'scrollToPrimeraCita', value );
            vistaCalendario.current.scrollTo(0, value - 50);
        }
    }

    const onClickCita = ({ cita }) => {
        // console.log('cita', cita);
        //console.log( 'editCita', editCita );
        if (!editCita) {
            // console.log( '!editCita' );
            dispatch(activeCita(cita._id, cita));
            /* if( citaActiva ){
                if( citaActiva._id === cita._id ){
                    dispatch( unactiveCita() );
                } else {
                    dispatch( activeCita( cita._id, cita ) );
                }
            } else {
                dispatch( activeCita( cita._id, cita ) );
            } */

            /* const elUsuario = usuarios.filter( item => (cita.usuario._id) ? item._id === cita.usuario._id : item._id === cita.usuario );
            if( elUsuario.length > 0 ){
                dispatch( activeUsuario( elUsuario[0]._id, elUsuario[0] ) );
                dispatch( activeServicio( cita.servicio._id, cita.servicio ));
                dispatch( activeSucursal( cita.sucursal._id, cita.sucursal ));
                dispatch( activeProfesional( cita.profesional._id, cita.profesional ));
                setEditCita( true );
            } */
            dispatch(activeUsuario(cita.usuario._id, cita.usuario));
            dispatch(activeServicio(cita.servicios[0]._id, cita.servicios[0]));
            dispatch(activeSucursal(cita.sucursal._id, cita.sucursal));
            dispatch(activeProfesional(cita.profesional._id, cita.profesional));
            setEditCita(true);
            setActiveDnd(false);
        }
    }

    const twoDecimals = (number) => {
        return parseFloat(parseFloat(number).toFixed(2));
    }

    const handleStart = (ev, ui) => {
        /* console.log( 'Start', ev );
        console.log( 'Start', ui );
        let valor = 0;
        const prova = setInterval( () => {
            valor += 300;
            console.log( valor);
        }, 300 );
        if( valor >= 2000 ) {
            clearInterval( prova );
        } */
       console.log('handleStart: ', ev, ui);
    }

	

    const handleEvent = (ev, ui) => {
        const idColumna = parseInt(ui.node.getAttribute('data-column'));
        let peticionAprobada = true;

        if (ui.lastY !== 0) {
            // Obtener datos de la cita principal
            const citaId = ui.node.getAttribute('data-_id');
            const duracion = parseInt(ui.node.getAttribute('data-duracion'));
            const posicionSolicitadaInicio = twoDecimals(parseFloat(ui.node.dataset.top) + parseFloat(ui.lastY));
            const posicionSolicitadaFin = twoDecimals(duracion * (altoFranjaDraggable / espaciadoEmpresa) + posicionSolicitadaInicio);
            const diferencia = (parseFloat(ui.lastY) / altoFranjaDraggable) * espaciadoEmpresa;

            // Encontrar la cita actual y su relacionada
            let citaActual = null;
            let citaRelacionada = null;
            
            dailyItem.calendarItems.forEach(columna => {
                columna.items.forEach(item => {
                    if (item.cita._id === citaId) {
                        citaActual = item.cita;
                    }
                    if (citaActual?.citaRelacionada && item.cita._id === citaActual.citaRelacionada) {
                        citaRelacionada = item.cita;
                    }
                });
            });

            if (!citaActual) return;

            // Verificar colisiones para la cita principal
            cuadrante.forEach(columna => {
                columna.forEach(item => {
                    if (item.cita._id !== citaId && item.cita._id !== citaActual?.citaRelacionada) {
                        const citaInicio = twoDecimals(item.currentTop);
                        const citaFin = twoDecimals(item.duracion * (altoFranjaDraggable / espaciadoEmpresa) + item.currentTop);

                        // Verificar colisión para cita principal
                        if (item.currentColumn === idColumna) {
                            if ((posicionSolicitadaInicio >= citaInicio && posicionSolicitadaInicio < citaFin) || 
                                (posicionSolicitadaFin > citaInicio && posicionSolicitadaFin <= citaFin)) {
                                peticionAprobada = false;
                            }
                        }

                        // Si hay cita relacionada, verificar sus colisiones también
                        if (citaRelacionada) {
                            const diferenciaTiempo = moment(citaRelacionada.fecha).diff(moment(citaActual.fecha), 'minutes');
                            const posicionRelacionadaInicio = posicionSolicitadaInicio + 
                                (diferenciaTiempo * (altoFranjaDraggable / espaciadoEmpresa));
                            const posicionRelacionadaFin = posicionRelacionadaInicio + 
                                (citaRelacionada.duracion * (altoFranjaDraggable / espaciadoEmpresa));

                            if ((posicionRelacionadaInicio >= citaInicio && posicionRelacionadaInicio < citaFin) || 
                                (posicionRelacionadaFin > citaInicio && posicionRelacionadaFin <= citaFin)) {
                                peticionAprobada = false;
                            }
                        }
                    }
                });
            });

            if (peticionAprobada) {
                dispatch(openLoading());
                
                // Calcular nueva fecha para cita principal
                const newFecha = diferencia > 0 
                    ? moment(citaActual.fecha).add(Math.abs(diferencia), 'minutes')
                    : moment(citaActual.fecha).subtract(Math.abs(diferencia), 'minutes');

                const reserva = {
                    ...citaActual,
                    fecha: newFecha.toISOString(),
                    internal: true,
					usuario: citaActual.usuario._id,
					profesional: citaActual.profesional._id
                };

                // Si hay cita relacionada, preparar su actualización
                if (citaRelacionada) {
                    const diferenciaTiempo = moment(citaRelacionada.fecha).diff(moment(citaActual.fecha), 'minutes');
                    const newFechaRelacionada = moment(newFecha).add(diferenciaTiempo, 'minutes');

                    const reservaRelacionada = {
                        ...citaRelacionada,
                        fecha: newFechaRelacionada.toISOString(),
                        internal: true,
						usuario: citaRelacionada.usuario._id,
						profesional: citaRelacionada.profesional._id
                    };

                    // Enviar ambas actualizaciones
                    if (usarSockets) {
                        socket?.emit('grabar-cita', { 
                            uid, 
                            reserva, 
                            reservaRelacionada,
                            internal: true 
                        });
                    } else {
                        setDataModal({
                            title: '¿Modificar citas?',
                            text: ['¿Estás seguro de modificar estas citas relacionadas?'],
                            buttonText: 'Estoy seguro',
                            button2Text: 'Cancelar'
                        });

                        setModalWaiting({ reserva, reservaRelacionada });
                        setModalTwoButtonsOpened(true);
                    }
                } else {
                    // Solo actualizar la cita principal
                    if (usarSockets) {
                        socket?.emit('grabar-cita', { uid, reserva, internal: true });
                    } else {
                        setDataModal({
                            title: '¿Modificar cita?',
                            text: ['¿Estás seguro de modificar esta cita?'],
                            buttonText: 'Estoy seguro',
                            button2Text: 'Cancelar'
                        });
                        setModalWaiting(reserva);
                        setModalTwoButtonsOpened(true);
                    }
                }
            } else {
                // Si hay colisión, regenerar el cuadrante para volver al estado original
                generaCuadrante();
            }
        }

        setActiveDnd(false);
    };

    return (
        <>
            {
                (viewValue === 'day' && altoFranja > 0)
                &&
                <div
                    className={`daily-view`}
                    ref={vistaCalendario}
					onScroll={handleScroll}
                >
                    {
                        horario.map((hora, idx) => (
                            <div key={uuid()} className={`horario-diario ${(idx === (horario.length - 1)) ? 'ultimo' : ''}`}>
                                <div className="hora">{`${hora} h`}</div>
                                <div key={uuid()} 
                                     className="franjas"
                                >
                                    {
                                        franjas.map(minuto => (
                                            <div
                                                key={uuid()}
                                                className="franja"
                                                style={{
                                                    height: altoFranja,
                                                    backgroundColor: over ? 'blue' : 'lightblue',
                                                    text: over ? 'white' : 'black',
                                                    fontStyle: 'bold'
                                                }}
                                            >{hora}:{minuto}</div>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                    <div className="citas-view columns">
                        <Suspense fallback={<p>Cargando...</p>}>
                            {
                                cuadrante?.map((column, idxColumn) => (
                                    <div
                                        key={uuid()}
                                        className={`daily-column size-${cuadrante.length}`}
                                        
                                    >
                                        {
                                            column.map((cita, idxCita) => (
                                                (cita.idxProf)
                                                    ?
                                                    <div
                                                        key={uuid()}
                                                        className='franja-item'
                                                        style={{
                                                            position: 'absolute',
                                                            top: `${cita.currentTop}px`,
                                                            width: '100%'
                                                        }}
                                                    >
                                                        <DailyCalendarItem
                                                            altoFranjaDraggable={altoFranjaDraggable}
                                                            handleEvent={handleEvent}
                                                            handleStart={handleStart}                                                            
															onClickCita={onClickCita}
															espaciadoEmpresa={espaciadoEmpresa}
                                                            cita={cita}
                                                            index={idxCita + (10000 * idxColumn)}
                                                            activeDnd={activeDnd}
															
                                                        />
                                                        <DailyCalendarItem
                                                            altoFranjaDraggable={altoFranjaDraggable}
                                                            handleEvent={handleEvent}
                                                            handleStart={handleStart}															
                                                            onClickCita={onClickCita}
															espaciadoEmpresa={espaciadoEmpresa}
                                                            cita={cita}
                                                            index={idxCita + (10000 * idxColumn)}
                                                            activeDnd={activeDnd}
                                                        />
                                                    </div>
                                                    :
                                                    <div
                                                        key={uuid()}
                                                        className='franja-item'
                                                        style={{
                                                            height: altoFranjaCita
                                                        }}
                                                    >
                                                    </div>
                                            )
                                            )
                                        }
                                    </div>
                                ))
                            }

                            {/* <DragDropContext onDragEnd={(result) => onDrop( result )}>
                {
                    cuadrante?.map( (column, idxColumn) => (
                        <div
                            key={ uuid() }
                            className={`daily-column size-${cuadrante.length}`}
                        >
                            {
                                column.map( (cita, idxCita) => (
                                    (cita.idxProf)
                                    ?
                                    <Droppable
                                        key={uuid()} 
                                        //droppableId={`${idxColumn}-${cita.idProfesional}-${cita.time}`}
                                        droppableId={
                                            JSON.stringify({
                                                idxColumn,
                                                cita
                                            })
                                        }
                                        isDropDisabled={true}
                                    >
                                        {(droppableProvided, snapshot) => (
                                            <div
                                                {...droppableProvided.droppableProps}
                                                ref={droppableProvided.innerRef}
                                                className="franja-item"
                                                style={{
                                                    backgroundColor: snapshot.isDraggingOver ? 'green' : 'transparent',
                                                    height: altoFranjaCita
                                                }}
                                            >
                                                <Draggable draggableId={cita.cita._id} key={cita.cita._id} index={idxCita + (10000 * idxColumn)}>
                                                    {(draggableProvided) => (
                                                        <div
                                                            {...draggableProvided.draggableProps}
                                                            ref={draggableProvided.innerRef}
                                                            {...draggableProvided.dragHandleProps}
                                                        >
                                                            <DailyCalendarItem
                                                                cita={cita} 
                                                                onClickCita={onClickCita} 
                                                                index={idxCita + (10000 * idxColumn)}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                                {droppableProvided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                    :
                                    <Droppable 
                                        key={uuid()} 
                                        //droppableId={`${idxColumn}-${cita.idProfesional}-${cita.time}`}
                                        droppableId={
                                            JSON.stringify({
                                                idxColumn,
                                                cita
                                            })
                                        }
                                    >
                                        {(droppableProvided, snapshot) => (
                                            <div
                                                {...droppableProvided.droppableProps}
                                                ref={droppableProvided.innerRef}
                                                className="franja-item"
                                                style={{
                                                    backgroundColor: snapshot.isDraggingOver ? 'green' : 'transparent',
                                                    height: altoFranjaCita
                                                }}
                                            >
                                                {droppableProvided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                ))
                            }
                        </div>
                    ))
                }
                </DragDropContext> */}
                        </Suspense>
                    </div>

                    <YlmModalTwoButtons
                        data={dataModal}
                        setModalResponse={setModalResponse}
                        modalTwoButtonsOpened={modalTwoButtonsOpened}
                        setModalTwoButtonsOpened={setModalTwoButtonsOpened}
                    />
                </div>
            }
        </>
    )
}
