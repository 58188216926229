import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { CloseIcon } from '../../../assets/svg/iconos';
import { DropdownAll } from '../../../components/forms/DropdownAll';
import { startSaveVenta } from '../../../actions/ventas';
import { getCita, refreshCita } from '../../../actions/citas';
import { DropdownDateWithoutCita } from '../../../components/forms/DropdownDateWithoutCita';
import { DropdownTimeWithoutCita } from '../../../components/forms/DropdownTimeWithoutCita';
import { setProfesionales } from '../../../actions/profesionales';
import { startUpdateVenta } from '../../../actions/ventas';
import { fetchConToken } from '../../../helpers/fetch';
import { DropdownClientes } from '../../../components/forms/DropdownClientes';
import { activeUsuario, unactiveUsuario } from '../../../actions/usuarios';
import { startLoadingProductos } from '../../../actions/productos';
import {  openModalBottom } from '../../../actions/ui';


const METODOS_PAGO = [    
    { _id: "Pago Tarjeta App", nombre: "Pago Tarjeta App" },
    { _id: "Pago Tarjeta", nombre: "Pago Tarjeta" },
    { _id: "Monedero", nombre: "Monedero" },
    { _id: "Monedero App", nombre: "Monedero App" },    
    { _id: "Pago en efectivo", nombre: "Pago en efectivo" },
];

export const ModalVentas = ({ 
    history, 
    citaActive, 
    activeModalVentas, 
    setActiveModalVentas,
    setActiveModalReserva,
    isOpen, 
    onClose,
    setDataModal,
    setModalTwoButtonsOpened,
    setCitaActive,
}) => {

	
	
    const dispatch = useDispatch();
    const { uid, empresa } = useSelector(state => state.auth);
    // Redux state
    const { colores } = useSelector(state => state.ui);
    const { servicios } = useSelector(state => state.servicios);
	const { sucursales } = useSelector(state => state.sucursales);
    const { promociones } = useSelector(state => state.promociones);
    const { variantes } = useSelector(state => state.variantes);
    const { profesionales } = useSelector(state => state.profesionales);
    const { usuarios, active: usuarioActivo } = useSelector(state => state.usuarios);
    const { productos } = useSelector(state => state.productos);
	

    // Estados locales para las selecciones
    const [selectedServices, setSelectedServices] = useState([]);
    const [selectedVariantes, setSelectedVariantes] = useState([]);
    const [selectedPromociones, setSelectedPromociones] = useState([]);
	const [selectedProfesional, setSelectedProfesional] = useState([]);
	const [selectedSucursal, setSelectedSucursal] = useState([]);
    const [metodoPago, setMetodoPago] = useState([]);
	const [ventaExistente, setVentaExistente] = useState(null);
    const [clienteData, setClienteData] = useState({
        nombre: '',
        apellidos: ''
    });

    const [total, setTotal] = useState(0);

    const [selectUsuario, setSelectUsuario] = useState({
        selected: null,
        visible: true,
        active: false,
    });

    const [selectedProducts, setSelectedProducts] = useState([]);

    // Añadir estado para el botón
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleTotalChange = (e) => {
        const value = e.target.value;
        
        // Permitir números negativos, positivos y un único punto decimal
        if (/^-?\d*\.?\d*$/.test(value)) {
            // Si es un punto decimal y ya hay uno, no hacer nada
            if (value.includes('.') && value.split('.').length > 2) return;
            
            // Si hay decimales, limitar a 2 decimales
            if (value.includes('.')) {
                const [integer, decimal] = value.split('.');
                if (decimal && decimal.length > 2) return;
            }
            
            setTotal(value);
        }
    };

	// Añade este useEffect después de los estados
	useEffect(() => {		
		console.log('citaActive: ', citaActive);
		if (citaActive) {
			if (citaActive.venta) {
				// Si existe una venta, cargar datos de la venta
				const fetchVenta = async () => {
					try {
						const resp = await fetchConToken(`ventas/${citaActive.venta}`);
						const data = await resp.json();						
						
						if (data.ok) {
							setVentaExistente(data.venta);
							setTotal(data.venta.importe);
							
							setSelectUsuario({
								_id: usuarioActivo._id,
								selected: usuarioActivo,
								visible: true,
								active: false
							});

							// Cargar datos del cliente
							setClienteData({
								nombre: data.venta.usuario?.nombre || '',
								apellidos: data.venta.usuario?.apellidos || ''
							});
							
							// Cargar datos de la venta
							setSelectedServices(data.venta.servicios || []);
							setSelectedVariantes(data.venta.variantes || []);
							setSelectedPromociones(data.venta.descuentos?.map(d => ({
								_id: d._id,
								nombre: d.descripcion
							})) || []);
							setSelectedProfesional(data.venta.profesional ? [data.venta.profesional] : []);
							setSelectedSucursal([data.venta.sucursal]);
							setMetodoPago([{_id: data.venta.metodoPago, nombre: data.venta.metodoPago}]);
							setSelectedProducts(data.venta.productos || []);
							
							setFechaCalendar(moment(data.venta.fechaCita));
							setTimeCalendar(moment(data.venta.fechaCita));
						}
					} catch (error) {
						console.error('Error al cargar la venta:', error);
					}
				};
				fetchVenta();
			} else {
				setVentaExistente(null);
				// Cargar datos de la cita
				setTotal(citaActive.importe);

				setClienteData({
					nombre: citaActive.usuario?.nombre || '',
					apellidos: citaActive.usuario?.apellidos || ''
				});
				dispatch(activeUsuario(citaActive.usuario._id, citaActive.usuario));
				setSelectUsuario({
					selected: citaActive.usuario,
					visible: true,
					active: false
				});
				setSelectedServices(citaActive.servicios ? [citaActive.servicios].flat() : []);
				setSelectedVariantes(citaActive.variantes ? [citaActive.variantes].flat() : []);
				setSelectedPromociones(citaActive.promocion ? 
					citaActive.promocion.map(promoId => ({
						_id: promoId,
						nombre: promociones.find(p => p._id === promoId)?.titulo || ''
					})) : []
				);
				setSelectedProfesional(citaActive.profesional ? [citaActive.profesional] : []);
				setSelectedSucursal(citaActive.sucursal ? [citaActive.sucursal] : []);
				if(METODOS_PAGO.find(m => m._id === citaActive.metodoPago)){
					setMetodoPago(citaActive.metodoPago ? [{_id: citaActive.metodoPago, nombre: citaActive.metodoPago}] : []);
				}else{					
					setMetodoPago([]);
				}
				
				setSelectedProducts([]);
				setFechaCalendar(moment(citaActive.fecha));
				setTimeCalendar(moment(citaActive.fecha));		
			}
		} else if (!selectUsuario.selected) {
			// New sale without reservation
			setVentaExistente(null);
			setTotal(0);
			setClienteData({
				nombre: '',
				apellidos: ''
			});
			dispatch(unactiveUsuario());
			setSelectedServices([]);
			setSelectedVariantes([]);
			setSelectedPromociones([]);
			setSelectedProfesional([]);
			setSelectedSucursal([]);
			setMetodoPago([]);
			setSelectedProducts([]);
			setFechaCalendar(moment());
			setTimeCalendar(moment());
		}
	}, [citaActive]);

	useEffect(() => {
		recalcularTotal();
	}, [selectedServices, selectedVariantes, selectedPromociones, selectedProducts]);

    
    const recalcularTotal = () => {
        let nuevoTotal = selectedServices.reduce((sum, service) => sum + (service.precio || 0), 0);
        
        // Para las variantes, buscamos el precio en el array de variantes del estado
        selectedVariantes.forEach(variante => {
            const varianteCompleta = variantes.find(v => v._id === variante._id);
            if (varianteCompleta) {
                nuevoTotal += varianteCompleta.valor || 0;
            }
        });
        
        selectedPromociones.forEach(promo => {
            const promoSelected = promociones.find(p => p._id === promo._id);
            if (promoSelected.porcentaje) {
                nuevoTotal -= ((nuevoTotal * (promoSelected.porcentaje/100)) / 100);
            } else if (promoSelected.cifra) {
                nuevoTotal -= promoSelected.cifra;
            }
        });
        
        // Sumar productos
        selectedProducts.forEach(product => {
            nuevoTotal += parseFloat(product.precio || 0);
        });
        
        setTotal(nuevoTotal/100);
    };

    const handleSubmit = async () => {
        if (isSubmitting) return; // Evitar múltiples clicks

        try {
            // Validaciones
            if (!selectUsuario.selected) {
                dispatch(openModalBottom({
                    text: ['El usuario es requerido'],
                    timeOut: 3
                }));
                return;
            }

            if (!selectedSucursal || selectedSucursal.length === 0) {
                dispatch(openModalBottom({
                    text: ['La sucursal es requerida'],
                    timeOut: 3
                }));
                return;
            }

            if (!selectedProfesional || selectedProfesional.length === 0) {
                dispatch(openModalBottom({
                    text: ['El profesional es requerido'],
                    timeOut: 3
                }));
                return;
            }

            setIsSubmitting(true); // Deshabilitar el botón

            const ventaData = {
                empresa,
                usuario: selectUsuario.selected._id,
                sucursal: selectedSucursal[0]?._id,
                profesional: selectedProfesional[0]?._id,
                fechaCita: moment(timeCalendar).toDate(),
                importe: parseFloat(total),
                descuentos: selectedPromociones.map(p => ({
                    _id: p._id,
                    descripcion: p.nombre,
                    importe: promociones.find(promo => promo._id === p._id)?.cifra || '0'
                })),
                servicios: selectedServices,
                variantes: selectedVariantes,
                metodoPago: metodoPago[0]?._id,
                productos: selectedProducts,
                
                ...(citaActive && {cita: citaActive._id})
            };

            let response;
            if (ventaExistente) {
                response = await dispatch(startUpdateVenta(ventaExistente._id, ventaData));
            } else {
                response = await dispatch(startSaveVenta(ventaData));
            }
			

            if (response.ok) {                
				if(citaActive){
					dispatch(refreshCita(citaActive._id, {...citaActive, venta: response.venta._id, pagada: true}));                
				}

				//await dispatch(getCita(citaActive._id, empresa));
                                                           
				setVentaExistente(ventaData);
				// Actualizar todos los estados locales con la nueva información
				
				
				const datos = {
					text: [ventaExistente ? 'El pago se ha modificado correctamente' : 'El pago se ha registrado correctamente'],
					timeOut: 3
				}
				dispatch(openModalBottom(datos));
                
                
                onClose();
            } else {
                setDataModal({
                    title: 'Error',
                    text: ['No se pudo procesar el pago. Por favor, inténtelo de nuevo.'],
                    buttonText: 'Aceptar',
                    button2Text: 'Cancelar'
                });
                setModalTwoButtonsOpened(true);
            }
        } catch (error) {
            console.error('Error al procesar el pago:', error);
            setDataModal({
                title: 'Error',
                text: ['Ha ocurrido un error al procesar el pago'],
                buttonText: 'Aceptar',
                button2Text: 'Cancelar'
            });
            setModalTwoButtonsOpened(true);
        } finally {
            setIsSubmitting(false); // Re-habilitar el botón
        }
    };
	const handleSucursalSelected = sucursal => {       
		setSelectedSucursal(sucursal);
    };
	const handleServicesChanged = (services) => {        
        setSelectedServices(services);		
    }
	const handleProfesionalSelected = profesional => {         
        setSelectedProfesional(profesional);

    };
	const handlePromocionesSelected = (promosSelected) => {		
        setSelectedPromociones(promosSelected.map(promo => ({
            _id: promo._id,
            nombre: promo.nombre
        })));		
    };

	const handleVariantesChanged = (variantes) => {
        setSelectedVariantes(variantes);    		
    }
	const handleMetodoPagoSelected = (metodoPago) => {
        setMetodoPago(metodoPago);    
    }

    const handleClose = () => {
        onClose();
        // Solo abrir el modal de reserva si hay una cita activa
        if (citaActive) {
            setActiveModalReserva(true);
        }
    };

	
	const [fechaCalendar, setFechaCalendar] = useState(citaActive?.fecha ? moment(citaActive.fecha) : moment());
	const [selectDate, setSelectDate] = useState(true);
	const [timeCalendar, setTimeCalendar] = useState(citaActive?.fecha ? moment(citaActive.fecha) : moment());
	const [selectTime, setSelectTime] = useState(true);

    const handleUsuarioSelected = usuario => {
        dispatch(activeUsuario(usuario._id, usuario));
        setSelectUsuario({
            _id: usuario._id,
            selected: usuario,
            visible: true,
            active: false
        });
        setClienteData({
            nombre: usuario.nombre || '',
            apellidos: usuario.apellidos || ''
        });
    };

    // Añadir función para resetear el formulario
    const resetForm = () => {
        setVentaExistente(null);
        setTotal(0);
        setClienteData({
            nombre: '',
            apellidos: ''
        });
        dispatch(unactiveUsuario());
        setSelectUsuario({
            selected: null,
            visible: true,
            active: false
        });
        setSelectedServices([]);
        setSelectedVariantes([]);
        setSelectedPromociones([]);
        setSelectedProfesional([]);
        setSelectedSucursal([]);
        setMetodoPago([]);
        setSelectedProducts([]);
        setFechaCalendar(moment());
        setTimeCalendar(moment());
    };

    // Añadir useEffect para detectar cuando se abre el modal
    useEffect(() => {
        if (activeModalVentas) {
            if (citaActive) {
                // Si hay cita activa, cargar sus datos
                if (citaActive.venta) {
                    // Lógica existente para venta existente
                    const fetchVenta = async () => {
                        try {
                            const resp = await fetchConToken(`ventas/${citaActive.venta}`);
                            const data = await resp.json();
                            
                            if (data.ok) {
                                setVentaExistente(data.venta);
                                // ... resto de setters para datos de venta existente
                            }
                        } catch (error) {
                            console.error('Error al cargar la venta:', error);
                        }
                    };
                    fetchVenta();
                } else {
                    // Cargar datos de la cita
                    setTotal(citaActive.importe);
                    setClienteData({
                        nombre: citaActive.usuario?.nombre || '',
                        apellidos: citaActive.usuario?.apellidos || ''
                    });
                    dispatch(activeUsuario(citaActive.usuario._id, citaActive.usuario));
                    setSelectUsuario({
                        selected: citaActive.usuario,
                        visible: true,
                        active: false
                    });
                    setSelectedServices(citaActive.servicios ? [citaActive.servicios].flat() : []);
                    setSelectedVariantes(citaActive.variantes ? [citaActive.variantes].flat() : []);
                    setSelectedPromociones(citaActive.promocion ? 
                        citaActive.promocion.map(promoId => ({
                            _id: promoId,
                            nombre: promociones.find(p => p._id === promoId)?.titulo || ''
                        })) : []
                    );
                    setSelectedProfesional(citaActive.profesional ? [citaActive.profesional] : []);
                    setSelectedSucursal(citaActive.sucursal ? [citaActive.sucursal] : []);
					if(METODOS_PAGO.find(m => m._id === citaActive.metodoPago)){
						setMetodoPago(citaActive.metodoPago ? [{_id: citaActive.metodoPago, nombre: citaActive.metodoPago}] : []);
					}else{
						setMetodoPago([]);	
					}
                    setSelectedProducts([]);
                    setFechaCalendar(moment(citaActive.fecha));
                    setTimeCalendar(moment(citaActive.fecha));
                }

            } else {
                // Si no hay cita activa, resetear el formulario
                resetForm();
            }
        }
    }, [activeModalVentas]);

    // Añadir handler para productos
    const handleProductsChanged = (products) => {
        setSelectedProducts(products);        
    };

    return (
        <>
            <div className={`modal-ventas ${activeModalVentas ? 'active' : ''}`}>
                <div 
                    className="modal-wrapper"
                    style={{ backgroundColor: '#FFFBD6' }}
                >
                    <div className="modal-title">
                        <div
                            className='btn-title'
                            onClick={handleClose}>
                            <h3 style={{ color: '#6366F1' }}>Venta</h3>
                        </div>
                        <div
                            className="btn-close"
                            style={{ backgroundColor: `#FFFBD6` }}
                            onClick={handleClose}
                        >
                            <CloseIcon color="#6366F1" />
                        </div>
                    </div>
                    <div className="modal-body">
                        {!citaActive && !selectUsuario.selected &&(
                            <DropdownClientes
                                selectUsuario={selectUsuario}
                                setSelectUsuario={setSelectUsuario}
                                handleUsuarioSelected={handleUsuarioSelected}
                                usuarios={usuarios}
                            />
                        )}
                        
                        {(citaActive || selectUsuario.selected) && (
                            <div className='modal-info-row'>
                                <p className='title'>Cliente:</p>
                                <p className='info'>
                                    &nbsp;{clienteData.nombre} {clienteData.apellidos}
                                </p>
                            </div>
                        )}
                        
                        {/* Servicios */}
                        <DropdownAll
                                items={servicios}
                                texto="Selecciona un servicio"
								itemsSelected = {selectedServices}
                                handleItemsSelected={handleServicesChanged}                                                  
                            />
						{/* Variantes */}
                        <DropdownAll
							items={variantes}                                							
							itemsSelected={selectedVariantes}
							handleItemsSelected={handleVariantesChanged}
						/>

						{/* Sucursales */}
						<DropdownAll                                                                
                                items={sucursales}                                
                                handleItemsSelected={handleSucursalSelected}
                                itemsSelected={selectedSucursal}
								oneSelected={true}
                                texto="Selecciona una sucursal *"
                                required={true}
                            />
						
						{/* Profesionales */}
						<DropdownAll							
							items={profesionales}
							handleItemsSelected={handleProfesionalSelected}
							itemsSelected={selectedProfesional}
							oneSelected={true}
                            texto="Selecciona un profesional *"
                            required={true}
						/>
											
						<DropdownDateWithoutCita
							selectDate={selectDate}
							setSelectDate={setSelectDate}
							fechaCalendar={fechaCalendar}
							setFechaCalendar={setFechaCalendar}
							disabled={true}
						/>
						<DropdownTimeWithoutCita
							selectTime={selectTime}
							setSelectTime={setSelectTime}
							timeCalendar={timeCalendar}
							setTimeCalendar={setTimeCalendar}
							selectServicio={selectedServices}
							selectSucursal={selectedSucursal}
							profesionales={profesionales}
							setProfesionales={setProfesionales}
							selectProfesional={selectedProfesional}
							setProfSelected={{}}
							fechaCalendar={fechaCalendar}
							setFechaCalendar={setFechaCalendar}
							morning={{}}
							afternoon={{}}
							editCita={true}
							disabled={true}
						/>
                        
                        {/* Método de Pago */}
						<DropdownAll
							items={METODOS_PAGO}
							handleItemsSelected={handleMetodoPagoSelected}
							itemsSelected={metodoPago}
							oneSelected={true}
						/>

						{/* Promociones */}
						<DropdownAll
							items={promociones}
							handleItemsSelected={handlePromocionesSelected}
							itemsSelected={selectedPromociones}
						/>

                        {/* Añadir después de Servicios */}
                        <DropdownAll
                            items={productos}
                            texto="Selecciona productos"
                            itemsSelected={selectedProducts}
                            handleItemsSelected={handleProductsChanged}
                        />

                        <div className="payment-section">
                            <div className="importe-container">
								<span>IMPORTE</span>
                                <div className='importe-container-inner'>									
									<input
										type="text"
										value={total}
										onChange={handleTotalChange}
									/>
									<span className="euro-symbol">€</span>
								</div>                                
                            </div>
                            <button 
                                className="completar-btn"
                                onClick={handleSubmit}
                                disabled={isSubmitting}
                                style={{
                                    opacity: isSubmitting ? 0.7 : 1,
                                    cursor: isSubmitting ? 'not-allowed' : 'pointer'
                                }}
                            >
                                {isSubmitting 
                                    ? 'Procesando...' 
                                    : (ventaExistente ? 'Modificar pago' : 'Completar pago')
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};





























                