import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { DropdownAll } from '../../components/forms/DropdownAll';
import { AddIcon, ArrowDownSmallIcon, BuscarIcon, CloseIcon, DownloadIcon } from '../../assets/svg/iconos';
import { setMenuLateral } from '../../actions/ui';
import { DropdownFilter } from '../../components/forms/DropdownFilter';
import { fetchConToken } from '../../helpers/fetch';
import { DownloadCSV } from '../../components/common/DownloadCSV';
import Calendar from 'react-calendar';

export const Ventas = () => {
    const dispatch = useDispatch();
    const { empresa } = useSelector(state => state.auth);
    const { colores, miniLateral } = useSelector(state => state.ui);
    const { sucursales } = useSelector(state => state.sucursales);
    const { profesionales } = useSelector(state => state.profesionales);

    // Estados
    const [searchActive, setSearchActive] = useState(false);
    const [searchTxt, setSearchTxt] = useState('');
    const [ventas, setVentas] = useState([]);
    const [filteredVentas, setFilteredVentas] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalFacturado, setTotalFacturado] = useState(0);
    

    // Estados para filtros
    const [dateRange, setDateRange] = useState({
        start: moment().subtract(7, 'days').startOf('day'),
        end: moment().endOf('day')
    });
    const [timeRange, setTimeRange] = useState({
        start: '08:30',
        end: '20:30'
    });
    const [selectedSucursal, setSelectedSucursal] = useState([]);
    const [selectedProfesional, setSelectedProfesional] = useState([]);

    // Estado para controlar qué dropdown está abierto
    const [activeDropdown, setActiveDropdown] = useState(null);

    // Estado para los filtros seleccionados
    const [selectedFilters, setSelectedFilters] = useState({
        servicio: [],
        descuento: [],
        variante: [],
        metodoPago: [],
        producto: []
    });

    const [activeTopDropdown, setActiveTopDropdown] = useState(null);

    const [calendarVisibility, setCalendarVisibility] = useState({
        start: false,
        end: false
    });
    const calendarRef = useRef();

    const handleDateInputClick = (inputRef) => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const startDateRef = useRef(null);
    const endDateRef = useRef(null);

    // Crear refs separadas para cada calendario
    const startCalendarRef = useRef(null);
    const endCalendarRef = useRef(null);

    useEffect(() => {
        dispatch(setMenuLateral('ventas'));
    }, [dispatch]);

    

    // Funciones de búsqueda
    const handleSearchChange = (e) => {
        setSearchTxt(e.target.value);
    };

    const clickSearch = () => {
        setSearchActive(!searchActive);
    };

    const clickDeleteSearch = () => {
        setSearchTxt('');
    };

    // Función para obtener las ventas
    const fetchVentas = async (filters = {}) => {
        try {			

            setIsLoading(true);

            const resp = await fetchConToken(`ventas/empresa/${empresa}`, {
                fechaInicio: filters.dateRange?.start || dateRange.start,
                fechaFin: filters.dateRange?.end || dateRange.end,                    
                sucursales: filters.sucursales || selectedSucursal.map(s => s._id),
                profesionales: filters.profesionales || selectedProfesional.map(p => p._id)
            }, 'POST');
			
            const body = await resp.json();

            if (body.ok) {
                setVentas(body.ventas);
                setFilteredVentas(body.ventas);
                setSelectedFilters({
                    servicio: [],
                    descuento: [],
                    variante: [],
                    metodoPago: [],
                    producto: []
                });
            } else {
                throw new Error(body.msg || 'Error al obtener las ventas');
            }
        } catch (error) {
            console.error('Error:', error);
            // Aquí podrías mostrar una notificación de error
        } finally {
            setIsLoading(false);
        }
    };

    // Efecto inicial para cargar las ventas y configurar el menú lateral
    useEffect(() => {
        dispatch(setMenuLateral('ventas'));		
        if (empresa) {
            fetchVentas();
        }
    }, [empresa, dispatch]);

    // Actualizamos los handlers de los filtros para usar fetchVentas
    const handleDateRangeChange = async (newRange) => {
        setDateRange(newRange);
        await fetchVentas({ dateRange: newRange });
    };

    const handleTimeRangeChange = async (newTimeRange) => {
        setTimeRange(newTimeRange);
        await fetchVentas({ timeRange: newTimeRange });
    };

    const handleSucursalChange = async (selected) => {
		
		const sucursalesSelected = sucursales.filter(s => selected.includes(s._id));
		
        setSelectedSucursal(sucursalesSelected);
        setActiveTopDropdown(null);
        await fetchVentas({ 
            sucursales: sucursalesSelected
        });
    };

    const handleProfesionalChange = async (selected) => {
        
        const profesionalesSelected = profesionales.filter(p => selected.includes(p._id));
        
        setSelectedProfesional(profesionalesSelected);
        setActiveTopDropdown(null);
        await fetchVentas({ 
            profesionales: profesionalesSelected
        });
    };

    // Efecto para aplicar filtros
    useEffect(() => {
        const filteredResults = ventas.filter(venta => {
            const ventaDate = moment(venta.fechaCita);
            const ventaTime = ventaDate.format('HH:mm');
            
            // Filtro de fecha
            const isInDateRange = ventaDate.isBetween(
                moment(dateRange.start).startOf('day'),
                moment(dateRange.end).endOf('day'),
                'day',
                '[]'
            );

            // Filtro de hora
            const isInTimeRange = ventaTime >= timeRange.start && 
                                ventaTime <= timeRange.end;

            // Filtro de sucursal (verificamos que el ID exista)
            const isInSucursal = selectedSucursal.length === 0 || 
                                selectedSucursal.some(s => s._id === venta.sucursal?._id);

            // Filtro de profesional (verificamos que el ID exista)
            const isInProfesional = selectedProfesional.length === 0 || 
                                  selectedProfesional.some(p => p._id === venta.profesional?._id);

            // Para debug
            

            return isInDateRange && isInTimeRange && isInSucursal && isInProfesional;
        });

		console.log(filteredResults);

        setFilteredVentas(filteredResults);
    }, [dateRange, timeRange, selectedSucursal, selectedProfesional, ventas]);

    // Actualizar el total facturado cuando cambian las ventas filtradas
    useEffect(() => {
        const total = filteredVentas.reduce((sum, venta) => sum + (venta.importe || 0), 0);
        setTotalFacturado(total);
    }, [filteredVentas]);

    // Función para obtener opciones únicas del array de ventas
    const getUniqueOptions = (field) => {
        const options = new Set();
        
        ventas.forEach(venta => {
            if (field === 'servicio') {
                venta.servicios?.forEach(s => options.add(s.nombre));
            } else if (field === 'descuento') {
                venta.descuentos?.forEach(d => options.add(d.descripcion));
            } else if (field === 'variante') {
                venta.variantes?.forEach(v => options.add(v.nombre));
            } else if (field === 'metodoPago') {
                options.add(venta.metodoPago);
            } else if (field === 'producto') {
                venta.productos?.forEach(p => options.add(p.titulo));
            }
        });

        return Array.from(options).map(option => ({
            id: option,
            label: option
        }));
    };

    // Función para obtener opciones de los estados de Redux
    const getOptions = (field) => {
        if (field === 'sucursal') {
            return sucursales.map(sucursal => ({
                id: sucursal._id,
                label: sucursal.nombre
            }));
        } else if (field === 'profesional') {
            return profesionales.map(profesional => ({
                id: profesional._id,
                label: profesional.nombre
            }));
        }
        
        // Para el resto de campos usamos el método existente
        return getUniqueOptions(field);
    };

    // Función para manejar el click en las cabeceras
    const handleHeaderClick = (columnName) => {
        if (activeDropdown === columnName) {
            setActiveDropdown(null);
        } else {
            setActiveDropdown(columnName);
        }
    };

    // Función para manejar la selección de filtros
    const handleFilterSelect = (columnName, selectedOptions) => {
        setSelectedFilters(prev => ({
            ...prev,
            [columnName]: selectedOptions
        }));
        setActiveDropdown(null);
    };

    // Función para obtener el texto a mostrar en los filtros superiores
    const getDisplayText = (selected, type) => {
        if (!selected || selected.length === 0) {
            return type === 'sucursal' ? 'Todas las sucursales' : 'Todos los profesionales';
        }
        if (selected.length === 1) {
            return selected[0].nombre;
        }
        return `${selected.length} seleccionados`;
    };

    // Manejador para abrir/cerrar los dropdowns superiores
    const handleTopFilterClick = (filterType) => {
        setActiveTopDropdown(activeTopDropdown === filterType ? null : filterType);
    };

    // Función para formatear la fecha para el input date (YYYY-MM-DD)
    const formatDateForInput = (momentDate) => {
        return momentDate.format('YYYY-MM-DD');
    };

    // Función para formatear la fecha para mostrar (DD/MM/YYYY)
    const formatDateForDisplay = (momentDate) => {
        return momentDate.format('DD/MM/YYYY');
    };

    // Función para parsear la fecha desde formato europeo
    const parseDateFromEuropean = (dateString) => {
        const [day, month, year] = dateString.split('/');
        return moment(`${year}-${month}-${day}`);
    };

    // useEffect para aplicar los filtros de cabecera
    useEffect(() => {
        // Empezamos con los datos que ya tenemos de la API
        let results = [...ventas];

        // Aplicamos los filtros de cabecera
        if (selectedFilters.servicio.length > 0) {
            results = results.filter(venta => 
                venta.servicios?.some(s => selectedFilters.servicio.includes(s.nombre))
            );
        }

        if (selectedFilters.descuento.length > 0) {
            results = results.filter(venta =>
                venta.descuentos?.some(d => selectedFilters.descuento.includes(d.descripcion))
            );
        }

        if (selectedFilters.variante.length > 0) {
            results = results.filter(venta =>
                venta.variantes?.some(v => selectedFilters.variante.includes(v.nombre))
            );
        }

        if (selectedFilters.metodoPago.length > 0) {
            results = results.filter(venta =>
                selectedFilters.metodoPago.includes(venta.metodoPago)
            );
        }

        if (selectedFilters.producto.length > 0) {
            results = results.filter(venta =>
                venta.productos?.some(p => selectedFilters.producto.includes(p.titulo))
            );
        }

        setFilteredVentas(results);
    }, [selectedFilters, ventas]); // Solo depende de los filtros de cabecera y los datos originales

    // Definimos las cabeceras para el CSV
    const headersCSV = 'Fecha|Cliente|Servicio|Descuento|Variante|Producto|Método de Pago|Importe';

    // Función para manejar clicks fuera del calendario
    const useOutsideClick = (ref, callback, when) => {
        const savedCallback = useRef(callback);

        useEffect(() => {
            savedCallback.current = callback;
        });

        const handler = (e) => {
            // Verificar si el click fue dentro del calendario
            const isCalendarClick = e.target.closest('.react-calendar');
            
            // Solo ejecutar el callback si el click fue fuera del calendario y del ref
            if (ref.current && !ref.current.contains(e.target) && !isCalendarClick) {
                savedCallback.current();
            }
        };

        useEffect(() => {
            if (when) {
                document.addEventListener('click', handler);
                return () => document.removeEventListener('click', handler);
            }
        }, [when]);
    };

    // Usar el hook para cada calendario
    useOutsideClick(startCalendarRef, () => {
        setCalendarVisibility(prev => ({ ...prev, start: false }));
    }, calendarVisibility.start);

    useOutsideClick(endCalendarRef, () => {
        setCalendarVisibility(prev => ({ ...prev, end: false }));
    }, calendarVisibility.end);

    // Handler para mostrar/ocultar calendario
    const toggleCalendar = (type) => {
        setCalendarVisibility(prev => ({
            start: type === 'start' ? !prev.start : false,
            end: type === 'end' ? !prev.end : false
        }));
    };

    // Handler para cambio de fecha
    const handleDateChange = (date, type) => {
        const newDate = moment(date);
        handleDateRangeChange({
            ...dateRange,
            [type]: type === 'end' ? newDate.endOf('day') : newDate.startOf('day')
        });
        setCalendarVisibility({ start: false, end: false });
    };

    return (
        <div 
            className="ventas-screen"
            style={{
                '--colorPrincipal': colores.colorPrincipal,
                '--colorSecundario': colores.colorSecundario,
                '--color3': colores.color3,
                '--color4': colores.color4,
                '--color5': colores.color5,
                '--color6': colores.color6,
                '--color7': colores.color7,
            }}
        >
            <div className={`preheader ${!miniLateral && 'compress'}`}>
                <div className="title">
                    <h1 style={{color: colores.colorPrincipal}}>Facturación y estadísticas</h1>
                </div>                
                
            </div>

            <div className="filters">
                <div className="filter-group">
                    <label>FECHAS / PERIODO</label>
                    <div className="date-range">
                        <div className="date-input">
                            <div 
                                className="date-display"
                                onClick={() => toggleCalendar('start')}
                            >
                                {formatDateForDisplay(dateRange.start)}
                            </div>
                            <div
                                className={`calendar-dropdown ${calendarVisibility.start ? 'active' : ''}`}
                                ref={startCalendarRef}
                            >
                                <Calendar
                                    onChange={(date) => handleDateChange(date, 'start')}
                                    value={dateRange.start.toDate()}
                                    locale="es-ES"
                                />
                            </div>
                        </div>
                        <span>-</span>
                        <div className="date-input">
                            <div 
                                className="date-display"
                                onClick={() => toggleCalendar('end')}
                            >
                                {formatDateForDisplay(dateRange.end)}
                            </div>
                            <div
                                className={`calendar-dropdown ${calendarVisibility.end ? 'active' : ''}`}
                                ref={endCalendarRef}
                            >
                                <Calendar
                                    onChange={(date) => handleDateChange(date, 'end')}
                                    value={dateRange.end.toDate()}
                                    locale="es-ES"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="filter-group">
                    <label>JORNADA</label>
                    <div className="time-range">
                        <input 
                            type="time"
                            value={timeRange.start}
                            onChange={(e) => handleTimeRangeChange({
                                ...timeRange, 
                                start: e.target.value
                            })}
                        />
                        <span>-</span>
                        <input 
                            type="time"
                            value={timeRange.end}
                            onChange={(e) => handleTimeRangeChange({
                                ...timeRange, 
                                end: e.target.value
                            })}
                        />
                        <span>h.</span>
                    </div>
                </div>

                <div className="filter-group">
                    <label>SUCURSAL</label>
                    <div 
                        className="filter-header"
                        onClick={() => handleTopFilterClick('sucursal')}
                    >
                        <span>{getDisplayText(selectedSucursal, 'sucursal')}</span>
                        
                    </div>
                    {activeTopDropdown === 'sucursal' && (
                        <div className="filter-dropdown">
                            <DropdownFilter
                                options={sucursales.map(s => ({
                                    id: s._id,
                                    label: s.nombre,
                                    checked: selectedSucursal.some(selected => selected._id === s._id)
                                }))}
                                selected={selectedSucursal.map(s => s._id)}
                                onChange={handleSucursalChange}
                            />
                        </div>
                    )}
                </div>

                <div className="filter-group">
                    <label>PROFESIONAL</label>
                    <div 
                        className="filter-header"
                        onClick={() => handleTopFilterClick('profesional')}
                    >
                        <span>{getDisplayText(selectedProfesional, 'profesional')}</span>
                        
                    </div>
                    {activeTopDropdown === 'profesional' && (
                        <div className="filter-dropdown">
                            <DropdownFilter
                                options={profesionales.map(p => ({
                                    id: p._id,
                                    label: p.nombre,
                                    checked: selectedProfesional.some(selected => selected._id === p._id)
                                }))}
                                selected={selectedProfesional.map(p => p._id)}
                                onChange={handleProfesionalChange}
                            />
                        </div>
                    )}
                </div>

                <div className="filter-group total-facturado">
                    <label>IMPORTE FACTURADO</label>
                    <div className="total-amount">
                        {totalFacturado.toFixed(2)}€
                    </div>
                </div>
            </div>

            <div className="table-ventas">
                <div className='table-row headers-row'>
                    <div className='column cliente'>
                        <div className='column-header'>
                            <p>Cliente</p>
                        </div>
                    </div>

                    <div className='column servicio'>
                        <div 
                            className='column-header'
                            onClick={() => handleHeaderClick('servicio')}
                        >
                            <p>Servicio</p>
                            
                        </div>
                        {activeDropdown === 'servicio' && (
                            <div className="filter-dropdown">
                                <DropdownFilter
                                    options={getOptions('servicio')}
                                    selected={selectedFilters.servicio}
                                    onChange={(selected) => handleFilterSelect('servicio', selected)}
                                />
                            </div>
                        )}
                    </div>

                    <div className='column descuento'>
                        <div 
                            className='column-header'
                            onClick={() => handleHeaderClick('descuento')}
                        >
                            <p>Descuento</p>
                            
                        </div>
                        {activeDropdown === 'descuento' && (
                            <div className="filter-dropdown">
                                <DropdownFilter
                                    options={getOptions('descuento')}
                                    selected={selectedFilters.descuento}
                                    onChange={(selected) => handleFilterSelect('descuento', selected)}
                                />
                            </div>
                        )}
                    </div>

                    <div className='column variante'>
                        <div 
                            className='column-header'
                            onClick={() => handleHeaderClick('variante')}
                        >
                            <p>Variante</p>
                            
                        </div>
                        {activeDropdown === 'variante' && (
                            <div className="filter-dropdown">
                                <DropdownFilter
                                    options={getOptions('variante')}
                                    selected={selectedFilters.variante}
                                    onChange={(selected) => handleFilterSelect('variante', selected)}
                                />
                            </div>
                        )}
                    </div>

                    <div className='column producto'>
                        <div 
                            className='column-header'
                            onClick={() => handleHeaderClick('producto')}
                        >
                            <p>Producto</p>
                            
                        </div>
                        {activeDropdown === 'producto' && (
                            <div className="filter-dropdown">
                                <DropdownFilter
                                    options={getOptions('producto')}
                                    selected={selectedFilters.producto}
                                    onChange={(selected) => handleFilterSelect('producto', selected)}
                                />
                            </div>
                        )}
                    </div>

                    <div className='column metodoPago'>
                        <div 
                            className='column-header'
                            onClick={() => handleHeaderClick('metodoPago')}
                        >
                            <p>Método de pago</p>
                            
                        </div>
                        {activeDropdown === 'metodoPago' && (
                            <div className="filter-dropdown">
                                <DropdownFilter
                                    options={getOptions('metodoPago')}
                                    selected={selectedFilters.metodoPago}
                                    onChange={(selected) => handleFilterSelect('metodoPago', selected)}
                                />
                            </div>
                        )}
                    </div>

                    <div className="column importe">
                        <div className="column-header">
                            <p>Importe</p>
                        </div>
                    </div>

                    <div className="column download">
                        <div className="column-header">
                            {filteredVentas?.length > 0 && 
                                <DownloadCSV 
                                    headers={headersCSV} 
                                    data={filteredVentas.map(venta => ({
                                        fecha: moment(venta.fechaCita).format('DD/MM/YYYY'),
                                        cliente: `${venta.usuario?.nombre} ${venta.usuario?.apellidos}`,
                                        servicio: venta.servicios?.map(s => s.nombre).join(', ') || '',
                                        descuento: venta.descuentos?.map(d => d.descripcion).join(', ') || '',
                                        variante: venta.variantes?.map(v => v.nombre).join(', ') || '',
                                        producto: venta.productos?.map(p => p.titulo).join(', ') || '',
                                        metodoPago: venta.metodoPago || '',
                                        importe: venta.importe?.toFixed(2) || '0.00'
                                    }))} 
                                    fileName={'listadoVentas'} 
                                    buttonText={''} 
                                    delimiter={';'}
                                    icon={<DownloadIcon color={colores.colorPrincipal} />}
                                />
                            }
                        </div>
                    </div>
                </div>
                <div className='table-scrollable'>
                    {isLoading ? (
                        <div className="loading-ventas">
                            <div className="bg-loading"></div>
                            <p>Cargando ventas...</p>
                        </div>
                    ) : (
                        filteredVentas.map(venta => (
                            <div key={venta._id} className="table-row">
                                <div className="column cliente">
                                    {venta.usuario?.nombre} {venta.usuario?.apellidos}
                                </div>
                                <div className="column servicio">
                                    {venta.servicios?.map(s => s.nombre).join(', ')}
                                </div>
                                <div className="column descuento">
                                    {venta.descuentos?.map(d => d.descripcion).join(', ')}
                                </div>
                                <div className="column variante">
                                    {venta.variantes?.map(v => v.nombre).join(', ')}
                                </div>
                                <div className="column producto">
                                    {venta.productos?.map(p => p.titulo).join(', ')}
                                </div>
                                <div className="column metodoPago">
                                    {venta.metodoPago}
                                </div>
                                <div className="column importe">
                                    {venta.importe?.toFixed(2)}€
                                </div>
                                <div className="column download"></div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
}; 