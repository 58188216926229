import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { HorarioSelect } from '../tools/HorarioSelect';

import { startLoadingHorarios } from '../../actions/horarios';

const timezone = process.env.REACT_APP_TIMEZONE;

const useOutsideClick = ( ref, callback, when ) => {
    const savedCallback = useRef( callback );

    useEffect(() => {
        savedCallback.current = callback;
    });

    const handler = (e) => {
        if( ref.current && !ref.current.contains(e.target)) {
            savedCallback.current();
        }
    };

    useEffect(() => {
        if (when) {
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [when]);
}

export const DropdownTimeWithoutCita = ( { selectTime, setSelectTime, timeCalendar, setTimeCalendar, selectSucursal, profesionales, setProfesionales, selectProfesional, setProfSelected, fechaCalendar, setFechaCalendar, morning, afternoon, editCita = false, activeModalReserva, inputRef, onInputClick } ) => {
    const [visibility, setVisibility] = useState(false);
    const {empresa} = useSelector(state => state.auth);
    const dropdownMenuRef = useRef();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(startLoadingHorarios(empresa));
    }, []);    

    const showDropdown = () => {
        if( selectTime.visible ){
            setVisibility(true);
        }
    }

    const hideDropdown = () => {
        setVisibility(false);
    }

    useOutsideClick( dropdownMenuRef, hideDropdown, visibility );

    // Asegurarnos de que timeCalendar sea un objeto moment
    const time = moment.isMoment(timeCalendar) ? timeCalendar : moment(timeCalendar);

    return (
		<div className="brb-select">
			<div className="brb-select-container">
				<p>{ ( moment(timeCalendar).year() === 1972) ? 'Selecciona la hora' : moment.tz(timeCalendar, timezone).format("HH:mm") }</p>				
			</div>
		</div>
    )
}
