import React, { useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { barberiaNow } from '../../helpers/calendar';
import { useSelector } from 'react-redux';
import moment from 'moment';

const useOutsideClick = ( ref, callback, when ) => {
    const savedCallback = useRef( callback );

    useEffect(() => {
        savedCallback.current = callback;
    });

    const handler = (e) => {
        if( ref.current && !ref.current.contains(e.target)) {
            savedCallback.current();
        }
    };

    useEffect(() => {
        if (when) {
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [when]);
}

export const DropdownDateWithoutCita = ({ 
    selectDate, 
    setSelectDate, 
    fechaCalendar, 
    setFechaCalendar, 
    setTimeCalendar,
    disabled = false,
    inputRef,
    onInputClick
}) => {
    const { colores } = useSelector(state => state.ui);
    const [visibility, setVisibility] = useState(false);
    const dropdownMenuRef = useRef();

    // Asegurarnos de que fechaCalendar sea un objeto moment
    const fecha = moment.isMoment(fechaCalendar) ? fechaCalendar : moment(fechaCalendar);

    const showDropdown = () => {
        if (selectDate.visible && !disabled) {
            setVisibility(true);
        }
    }

    const hideDropdown = () => {
        setVisibility(false);
    }

    const dayClicked = () => {
        if (!disabled) {
            setTimeCalendar(new Date(1972,10,13,0,0));
            (visibility) ? hideDropdown() : showDropdown();
        }
    };

    const dateChange = (e) => {
        if (!disabled) {
            setFechaCalendar(e);
        }
    };

    useOutsideClick(dropdownMenuRef, hideDropdown, visibility);

    return (
		<div className="brb-select">
			<div className="brb-select-container">
				<p>{moment(fecha).format("DD-MM-YYYY")}</p>
			</div>
		</div>        
    );
}
